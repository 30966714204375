import React from 'react'
import Card from '@mui/material/Card';

import { Divider } from '@mui/material';

const EditorsGuidlines = () => {
    return (
        <>
            <Card>
                <div className='p-4'>
                    <h3 className='text-uppercase'>EDITOR GUIDELINES </h3>
                </div>
            </Card>
            <Divider />
            <Card>
                <div className="row">
                    <div className="col-12 p-4">



                        <div className="col-12 m-2">
                            <p>

                                Editorial Board Members Role and Responsibilities
                            </p>

                            <p>

                                The editorial board, also referred to as the (editorial) advisory committee, is comprised of experts in the journal's field, some of whom may serve on other journal boards. Their roles and responsibilities include:
                            </p>

                            <p>
                                <strong>
                                    1.	Expertise in Subject Matter:</strong> Editorial board members should have a strong background and expertise in the subject matter covered by the journal.
                            </p>
                            <p>
                                <strong>
                                    2.	Reviewing Submitted Manuscripts:</strong> They are responsible for reviewing and evaluating manuscripts submitted to the journal, providing constructive feedback, and making recommendations for publication.
                            </p>

                            <p>
                                <strong>
                                    3.	Advising on Journal Policy and Scope:</strong> Editorial board members offer guidance on the journal's policies, focus, and scope, ensuring alignment with the field's current trends and needs.
                            </p>

                            <p>
                                <strong>
                                    4.	Identifying Special Topics and Conferences: </strong> They can propose special issue topics and may assist in organizing and guest-editing these issues. This helps diversify journal content.
                            </p>
                            <p>

                                <strong>
                                    5.	Attracting Authors:</strong> Board members can actively encourage researchers to submit their work to the journal, expanding its author base.
                            </p>

                            <p>
                                <strong>
                                    6.	Submitting Their Work:</strong> Ideally, board members should contribute their own research to the journal, setting an example for potential authors.
                            </p>
                            <p>
                                <strong>
                                    7.	Maintaining Manuscript Flow:</strong> Ensuring a steady flow of high-quality manuscripts to the publishing house to meet the journal's publication schedule.
                            </p>

                            <p>
                                <strong>
                                    8.	Staying Informed:</strong> Keeping abreast of changes and emerging trends in the field to reflect these shifts in the journal's content. This may involve inviting articles or special issues.
                            </p>
                            <p>
                                <strong>
                                    9.	Participating in Editorial Board Selection:</strong> Collaborating with the publishing contact to select new editorial board members who can contribute effectively.
                            </p>

                            <p>
                                <strong>
                                    10.	Engaging with the Board:</strong> Regularly updating and involving the editorial board in the journal's progress and development through formal or informal meetings.
                            </p>
                            <p>
                                <strong>
                                    11.	Providing Strategic Input:</strong> Offering strategic insights for the journal's growth and development, which the publishing contact can use for guidance.
                            </p>

                            <p>
                                <strong>
                                    12.	Promoting Revenue Opportunities:</strong> Encouraging commercial advertising, supplements, and reprint options that contribute to the journal's revenue.
                            </p>
                            <p>
                                <strong>
                                    13.	Promoting the Journal:</strong> Actively promoting the journal within their professional networks, among peers, and to colleagues, thereby increasing its visibility.
                            </p>
                            <p>

                                Given the large number of submissions and the journal's broad scope, one editor alone may not make informed decisions across all subject areas. Hence, editorial roles are often categorized, including Editor-in-Chief, Co-Editors, Associate Editors, Section Editors, Editorial Advisors, External Editors, and Guest Editors. Each plays a specific role in ensuring the journal's success and relevance in its field.
                            </p>


                        </div>



                    </div>
                </div>
            </Card>
            <br />
        </>
    )
}

export default EditorsGuidlines