import React from 'react'
import Card from '@mui/material/Card';

import { Divider } from '@mui/material';

const ReviewersGuidlines = () => {
    return (
        <>
            <Card>
                <div className='p-4'>
                    <h3 className='text-uppercase'>REVIEWER GUIDELINES </h3>
                </div>
            </Card>
            <Divider />
            <Card>
                <div className="row">
                    <div className="col-12 p-4">



                        <div className="col-12 m-2">
                            <p>

                                Reviewer Role and Responsibilities
                            </p>

                            <p>

                                Reviewers play a critical role in the scholarly publishing process, providing an impartial evaluation of submitted manuscripts and helping journals make informed publication decisions. Here are the key roles and responsibilities of reviewers:
                            </p>
                            <p>
                                <strong>
                                    1.	Assess Relevance:</strong> Reviewers evaluate the manuscript's relevance to the journal's scope and objectives. They determine whether the work contributes meaningfully to the field.
                            </p>

                            <p>
                                <strong>
                                    2.	Check for Novelty:</strong> Reviewers assess the originality of the manuscript, ensuring that it presents new and innovative ideas, methods, or findings.
                            </p>

                            <p>
                                <strong>
                                    3.	Evaluate Citations:</strong> Reviewers check that the manuscript appropriately cites and discusses relevant previous work in the field.
                            </p>

                            <p>
                                <strong>
                                    4.	Examine Methodology:</strong> Reviewers evaluate the appropriateness and adequacy of the research methodology described in the manuscript.
                            </p>

                            <p>
                                <strong>
                                    5.	Verify Conclusions:</strong> Reviewers assess whether the conclusions drawn in the manuscript are supported by the results presented.
                            </p>

                            <p>
                                <strong>
                                    6.	Assess Statistical Analyses:</strong> If applicable, reviewers check that any statistical analyses have been conducted correctly and are properly reported.
                            </p>

                            <p>
                                <strong>
                                    7.	Ensure Clarity:</strong> Reviewers ensure that the manuscript is written clearly and comprehensibly, even if English is not the author's first language.
                            </p>

                            <p>
                                <strong>
                                    8.	Language and Presentation:</strong> Reviewers may identify and suggest revisions for grammar, punctuation, spelling, and formatting issues.
                            </p>
                            <p>
                                <strong>
                                    9.	Technical Accuracy:</strong> They verify the accuracy of scientific notations, equations, superscripts, subscripts, figures, diagrams, references and bibliography, pointing out any errors.
                            </p>
                            <br />
                            <p>
                                <strong>

                                    Reviewing Process
                                </strong>
                            </p>
                            <p>

                                ISAR Publisher follows a 'Double-blind' peer review process, where both the authors' and reviewers' identities are concealed to avoid potential bias. Reviewers should adhere to the following opinions when evaluating manuscripts:
                            </p>
                            <p>
                                <strong>
                                    a. Major or Minor Revision: </strong> Manuscripts may require revisions, ranging from major changes (e.g., substantial rewriting, additional experiments) to minor adjustments (e.g., language and formatting).
                            </p>
                            <p>
                                <strong>
                                    b. Reviewer Rejection:</strong> Manuscripts that lack scientific validity or present a false approach may be rejected by reviewers.
                            </p>

                            <p>
                                <strong>
                                    c. Acceptance:</strong> Manuscripts that are well-written, easily understood, and supported by their results, while contributing significantly to existing knowledge, are acceptable as submitted.
                            </p>
                            <p>

                                Reviewers play a crucial role in maintaining the quality and integrity of academic publishing, ensuring that only high-quality research is published. Their feedback and assessments help authors improve their work and contribute to scientific progress.
                            </p>



                        </div>


                    </div>
                </div>
            </Card>
            <br />
        </>
    )
}

export default ReviewersGuidlines