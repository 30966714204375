import React from 'react'
import Card from '@mui/material/Card';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const OpenAccess = () => {
    return (
        <>

            <Card>
                <div className="row">
                    <div className="col-12 p-4">
                        <div className='m-3'>
                            <LockOpenIcon color="secondary" fontSize="large" />
                        </div>
                        <strong> OPEN ACCESS</strong>
                        <p>
                            Open access journals are journals whose articles are available worldwide free of charges. This approach breaks down traditional paywalls and allows information to be shared and accessed by a global audience.
                        </p>
                        <ul>
                            <li>No Content Submission Fees: These journals do not charge authors for submitting their content for consideration. This promotes inclusivity and encourages researchers from various backgrounds to contribute their work.
                            </li>

                            <li>
                                Free Accessibility: The articles published in open access journals are freely accessible on the publisher's website. This means that readers do not need a subscription or any form of payment to read or download the articles.

                            </li>
                        </ul>


                        <strong> ADVANTAGES OF OPEN ACCESS </strong>

                        <ul>
                            <li><strong> Global Accessibility:</strong> Open access ensures that research findings are accessible to anyone with an internet connection, regardless of their location or financial situation
                            </li>

                            <li>
                                <strong> Increased Visibility:</strong> Open access articles are often more discoverable, as they can be indexed by search engines and academic databases, leading to greater visibility for the authors' work.
                            </li>
                            <li>

                                <strong> Knowledge Dissemination:</strong> Open access promotes the rapid dissemination of knowledge, enabling faster progress in research and innovation.
                            </li>
                            <li>

                                <strong> Citation Impact:</strong> Studies have shown that open access articles tend to receive more citations, potentially increasing the impact of the research.
                            </li>
                            <li>

                                <strong> Public Engagement:</strong> Making research freely available allows the general public, policymakers, and practitioners to engage with and benefit from scholarly findings.
                            </li>
                            <li>

                                <strong> Collaboration: Open</strong> access facilitates collaboration between researchers globally, fostering interdisciplinary and cross-border research efforts.
                            </li>
                            <li>

                                <strong> Ethical Considerations:</strong> Open access aligns with the principles of knowledge sharing, transparency, and equal access to information.
                            </li>


                        </ul>
                        <p>
                            It's important to note that while open access journals do not charge readers for accessing content. It's also essential to verify the credibility and reputation of open access journals to ensure the quality of the published content.
                        </p>
                    </div>
                </div>
            </Card>

        </>
    )
}

export default OpenAccess;