import React from 'react'
import Card from '@mui/material/Card';
import { Divider } from '@mui/material';

const OpenAccess = () => {
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 p-4">
                        <h3> Open Access Policy</h3>
                    </div>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className="row">
                    <div className="col-12 p-4">
                        <p>
                            The open access policy adopted by ISAR Publisher is firmly rooted in the principles of open access, designed to promote unrestricted access to research articles and maximize their impact. By making all published research articles freely available, this policy eliminates the traditional barriers associated with paid subscriptions, ensuring that knowledge is accessible to a global audience without cost constraints.
                        </p>
                        <p>

                            The use of a Creative Commons Attribution (CC BY) license further amplifies this commitment to openness. Under this license, users are granted extensive rights to access, and distribute the content while requiring proper attribution to the original authors. This not only facilitates knowledge dissemination but also upholds the principles of academic integrity and authorship recognition.
                        </p>
                        <p>

                            Importantly, the policy emphasizes that open access applies to individual works, reinforcing the idea that each research article contributes to the broader goal of democratizing knowledge. Researchers and readers alike benefit from this policy by gaining the freedom to access, use, and share research findings without encumbrances, fostering a collaborative and inclusive research environment that ultimately advances the progress of science and benefits society as a whole. In summary, ISAR Publisher's open access policy embodies the core tenets of open science, promoting accessibility, impact, and collaboration in the realm of scholarly research.
                        </p>

                    </div>
                </div>
            </Card>
        </>
    )
}

export default OpenAccess;