// import handleCart from "./handleCart";
import handleJournal from "./handleJournal";


import { combineReducers } from "redux";

const rootReducers = combineReducers({
    // handleCart, 
    handleJournal
})

export default rootReducers;